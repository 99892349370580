document.addEventListener('DOMContentLoaded', function () {
  //////////////////// ADD ARROW BUTTON ////////////////////
  const htmlLinks = document.querySelectorAll(
    '.btn, .btn--outline, .btn--reverse, .wp-block-button:not(.is-style-btn-icon-pdf):not(.is-style-btn-icon-external) .wp-block-button__link'
  );

  htmlLinks.forEach(function (element) {
    const linkHtml = element.innerHTML;
    const linkSpan =
      "<span class='btn-icon btn-icon--first icon-ArrowLeft'></span>" +
      linkHtml +
      "<span class='btn-icon btn-icon--last icon-ArrowLeft'></span>";
    element.innerHTML = linkSpan;
  });
});
